import { render, staticRenderFns } from "./OrgErrorDialog.vue?vue&type=template&id=6566e505&scoped=true"
import script from "./OrgErrorDialog.vue?vue&type=script&lang=js"
export * from "./OrgErrorDialog.vue?vue&type=script&lang=js"
import style0 from "./OrgErrorDialog.vue?vue&type=style&index=0&id=6566e505&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6566e505",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrgDialogOneBtn: require('/codebuild/output/src1457052082/src/beeline-line/frontend-liff/src/components/OrgDialogOneBtn.vue').default})

import { render, staticRenderFns } from "./error.vue?vue&type=template&id=5b6caa19"
import script from "./error.vue?vue&type=script&lang=js"
export * from "./error.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrgError: require('/codebuild/output/src1457052082/src/beeline-line/frontend-liff/src/components/OrgError.vue').default})
